/* General Reset */
* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body,
html {
    height: 100%;
    margin: 0;
    font-family: sans-serif;
    background: linear-gradient(
        to bottom,
        #f7f7f7,
        /* Soft light gray at the top */ #e3e6e8 30%,
        /* Subtle transition to cool gray */ #cfd8dc 60%,
        /* Medium gray with a hint of blue */ #b0bec5 80%,
        /* Softer blue-gray for depth */ #90a4ae 100%
            /* Final settled blue-gray */
    );
    background-attachment: fixed;
    background-size: 100% 100%;
    color: #333; /* Dark gray text for readability */
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
    color: #37474f; /* Match the gradient theme */
}

h3 {
    font-size: x-large;
}

@media (prefers-color-scheme: dark) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #b4b4b4; /* Light text for dark mode */
    }
}

a {
    color: #007bff; /* Accent color for links */
    text-decoration: none;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

@media (prefers-color-scheme: dark) {
    body,
    html {
        height: 100%;
        margin: 0;
        background: linear-gradient(
                to bottom,
                #263238,
                /* Dark blue-gray */ #37474f 40%,
                /* Slightly lighter blue-gray transition */ #455a64 80%,
                /* Midpoint blue-gray */ #455a64 100% /* Final solid color */
            )
            no-repeat;
        background-attachment: fixed;
        color: #ddd; /* Lighten text color for readability */
    }
}

a {
    transition: all 0.4s;
}

a:hover {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
}

ul,
li {
    margin: 0px;
    list-style-type: none;
}

/* Table Styling */
.limiter {
    width: 100%;
    margin: 0 auto;
}

.home-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start; /* Align items at the top */
    padding: 20px;
    /* Space between the track and tables */
}

/* Wrapper for the tracks */
.track-container-wrapper {
    flex: 1; /* Allow this container to grow/shrink based on content */
    display: flex;
    justify-content: center; /* Center the tracks horizontally */
    align-items: flex-start; /* Align tracks to the top */
}

/* Table container */
.table-container {
    flex: 2; /* Take up more space for the tables */
    display: flex;
    flex-direction: column; /* Stack Northbound and Southbound tables */
    gap: 20px; /* Space between the tables */
}

/* Optional: Adjust alignment for tables */
.table-container h3 {
    text-align: center;
}

.container-table100 {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    min-height: 100vh;
    background: linear-gradient(45deg, #c6c6c6, #a9ff89);
}

.wrap-table100 {
    width: 90%;
    max-width: 1200px;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
    background: white;
    border-radius: 10px;
    width: 100%;
    margin: 20px 0;
    position: relative;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

@media (prefers-color-scheme: dark) {
    table {
        background: #1e293b; /* Dark mode background */
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4); /* Softer shadow for dark mode */
    }
}

table td,
table th {
    padding: 8px;
    text-align: left;
}

.table100-head th {
    font-size: 18px;
    color: #fff;
    background-color: #636363;
    line-height: 1.2;
    padding: 2px 10px 2px 10px;
}

@media (prefers-color-scheme: dark) {
    .table100-head th {
        background-color: #455a64; /* Dark mode header background */
    }
}

.table100-head-dir th {
    font-size: 18px;
    color: #fff;
    background-color: #313131;
    padding: 2px 10px 2px 10px;
}

tbody tr:nth-child(even) {
    background-color: #f5f5f5;
}

tbody tr {
    font-size: 15px;
    color: #363636;
}

tbody tr:hover {
    background-color: #f0f0f0;
    cursor: pointer;
}

.column1 {
    width: 20%;
}

.column2 {
    width: 40%;
}

.column3 {
    width: 40%;
}

@media (prefers-color-scheme: dark) {
    tbody tr:nth-child(even) {
        background-color: #263238; /* Dark mode alternating row color */
    }

    tbody tr {
        background-color: #1e293b; /* Dark mode row background */
        color: #d0d0d0; /* Light text for dark mode rows */
    }

    tbody tr:hover {
        background-color: #37474f; /* Slightly lighter background for hover */
    }
}

/* Status Colors */
.greenbg {
    background-color: #b9ffb9;
    color: #005f00; /* Dark text for contrast */
}

.yellowbg {
    background-color: #ffffd4;
    color: #5f5f00; /* Dark text for contrast */
}

.redbg {
    background-color: #ffb5b5;
    color: #5f0000; /* Dark text for contrast */
}

/* Status Colors for Dark Mode */
@media (prefers-color-scheme: dark) {
    .greenbg {
        background-color: #005f00;
        color: #b9ffb9; /* Light text for contrast */
    }

    .yellowbg {
        background-color: #5f5f00;
        color: #ffffd4; /* Light text for contrast */
    }

    .redbg {
        background-color: #5f0000;
        color: #ffb5b5; /* Light text for contrast */
    }
}
/* Responsive Design */
@media (max-width: 768px) {
    table {
        border-spacing: 0;
        border-collapse: collapse;
        background: white;
        border-radius: 10px;
        width: 100%;
        margin: 0px 0;
        position: relative;
    }

    .home-container {
        flex-direction: row; /* Keep elements side-by-side if there's enough room */
        flex-wrap: wrap; /* Allow wrapping if necessary */
        justify-content: center; /* Center items when wrapped */
        align-items: flex-start; /* Keep alignment consistent */
    }

    .track-container-wrapper {
        min-width: 150px; /* Ensure tracks remain visible */
    }

    .table-container {
        width: 100%; /* Allow tables to take full width if necessary */
    }
}

.alert {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px 0;
    background-color: #ebcfcf;
    border-radius: 5px;
}

.alert-heading {
    font-size: 15px;
    margin-bottom: 10px;
}

@media (prefers-color-scheme: dark) {
    .alert-heading {
        color: #ffffff; /* Light text for dark mode */
    }
}

@media (prefers-color-scheme: dark) {
    .alert {
        color: #d0d0d0; /* Light text for dark mode */
        background-color: #312222;
    }
}

/* Base styles for the button */
.show-tracks-button {
    border: none;
    padding: 10px 15px;
    margin: 10px 0;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s, color 0.3s;
}

/* Dark mode styles */
@media (prefers-color-scheme: dark) {
    .show-tracks-button {
        background-color: #37474f;
        color: #fff;
    }
    .show-tracks-button:hover {
        background-color: #455a64;
    }
}

/* Light mode styles */
@media (prefers-color-scheme: light) {
    .show-tracks-button {
        background-color: #e0e0e0; /* Slightly darker gray on hover */

        color: #333; /* Dark text */
    }
    .show-tracks-button:hover {
        background-color: #acacac; /* Light gray background */
    }
}
