.trip-planner {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: var(--text-color);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.controls {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.station-selectors {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.station-selectors select {
  flex: 1;
  padding: 12px;
  font-size: 16px;
  border-radius: 8px; /* Softer corners for a modern look */
  border: 2px solid var(--border-color);
  background-color: var(--bg-color); /* Subtle background color */
  color: var(--text-color); /* Match text color */
  appearance: none; /* Remove default arrow styling */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%27http%3A//www.w3.org/2000/svg%27%20viewBox%3D%270%200%204%205%27%3E%3Cpath%20fill%3D%27%23999%27%20d%3D%27M2%200L0%202h4L2%200z%27/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center; /* Adjusted for padding */
  background-size: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.station-selectors select:hover {
  /* border-color: var(--active-border); Highlight border on hover */
  /* background-color: var(--button-hover-bg); Subtle hover background */
}

.station-selectors select:focus {
  /* border-color: var(--active-border); Highlight border on focus */
  outline: none; /* Remove default outline */
  /* background-color: var(--active-bg); Active background on focus */
  color: var(--active-text); /* High-contrast text color */
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .station-selectors {
    flex-direction: column; /* Stack selectors vertically */
    gap: 10px; /* Adjust spacing for smaller screens */
  }

  .station-selectors select {
    padding: 10px;
  }
}

.swap-button {
  padding: 5px;
  font-size: 20px;
  background-color: transparent;
  color: var(--button-text);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin: 10px auto;
}

@media (max-width: 768px) {
  .controls {
    flex-direction: column;
    align-items: center;
    /* Center align the controls */
    gap: 15px;
    /* Adjust spacing for mobile */
  }

  .swap-button {
    margin: 0px auto; /* Reduce vertical spacing */
    transform: rotate(90deg);
    width: 36px; /* Define a consistent size */
    height: 0px; /* Define a consistent size */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swap-button:hover {
    /* background-color: var(--button-hover-bg); */
    transform: rotate(90deg) scale(1.1); /* Slight hover effect */
  }
}

.train-results ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.train-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Align train details and buttons vertically */
  padding: 15px;
  margin-bottom: 10px;
  background: var(--card-bg);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.train-id {
  font-size: 18px;
  font-weight: bold;
}

.snarky-message {
  color: var(--error-color);
  font-weight: bold;
  margin: 10px 0;
  text-align: center;
}

:root {
  --bg-color: #f9f9f9;
  --text-color: #333;
  --border-color: #ccc;
  --button-bg: #007bff;
  --button-text: #000;
  --button-hover-bg: #0056b3;
  --button-text-hover: #fff;
  --active-bg: #3399ff; /* Distinct active background */
  --active-text: #fff; /* High contrast active text */
  --active-border: #007acc;
  --card-bg: #ffffff;
  /* Light mode card background */
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg-color: #1e1e1e;
    --text-color: #f1f1f1;
    --border-color: #555;
    --button-bg: #1e1e1e;
    --button-text: #fff;
    --button-hover-bg: #007acc;
    --button-text-hover: #fff;
    --active-bg: #007acc;
    --active-text: #fff;
    --active-border: #3399ff;
    --card-bg: #2e2e2e; /* Dark mode card background */
  }
}

.day-type-selector {
  display: flex;
  gap: 10px;
  width: 100%;
}

.day-type-button {
  flex: 1;
  margin: 0px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding: 5px;
  border: 2px solid var(--border-color); /* Border for definition */
  border-radius: 8px;
  background-color: var(--bg-color); /* Subtle background for unselected */
  color: var(--text-color); /* Text color to contrast background */
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.day-type-button.active {
  background-color: var(--active-bg); /* Visually distinct background */
  color: var(--active-text); /* High contrast text color */
  border-color: var(--active-border);
  font-weight: bold; /* Further emphasis */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  transform: scale(1.02); /* Slightly enlarged for active state */
}

.day-type-button:hover {
  background-color: var(--button-hover-bg);
  color: var(--button-text-hover);
  transform: scale(1.05); /* Enlarge slightly on hover */
}

@media (max-width: 768px) {
  .day-type-button {
    font-size: 16px;
    padding: 5px;
  }
}

.button-group {
  display: flex;
  align-items: center;
  gap: 10px; /* Add space between the buttons */
}

.train-actions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  /* Align buttons to the right */
  align-items: center;
  /* Ensure buttons are vertically aligned */
  margin-top: 10px;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
  /* Standard icon size */
  color: inherit;
  /* Match button text color */
}

@media (prefers-color-scheme: dark) {
  .calendar-button {
    color: var(--button-text);
  }
}

/* "More" button */
.calendar-button,
.more-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.more-button .material-symbols-outlined {
  font-size: 24px;
  color: var(--text-color);
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Slightly darker overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px); /* Modern blur effect */
  animation: fadeIn 0.3s ease-in-out; /* Smooth fade-in */
}

/* Modal Content */
.modal-content {
  background-color: var(--bg-color);
  padding: 24px;
  border-radius: 12px; /* Slightly larger radius for a softer look */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Stronger shadow for depth */
  text-align: center;
  max-width: 400px;
  width: 90%; /* Responsive width */
  animation: slideUp 0.3s ease-out; /* Smooth slide-up */
  transform: translateY(20px); /* Start position for slide-up */
}

/* Modal Buttons */
.modal-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px; /* More space between buttons */
  margin-top: 20px; /* Add spacing from content */
}

.modal-button {
  background-color: #b0bec5;
  border: none; /* Remove border */
  border-radius: 8px; /* Softer corners */
  padding: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500; /* Slightly bolder text */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--button-text);
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth hover animations */
}

.modal-button:hover {
  transform: scale(1.02); /* Subtle hover scaling */
}

/* Close Button */
.close-button {
  margin-top: 20px;
  padding: 12px;
  background-color: #b0bec5;
  color: var(--button-text);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600; /* Bolder text */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth hover animations */
}

.close-button:hover {
  transform: scale(1.05); /* Slightly larger hover effect */
}

/* Modern Dark Mode */
@media (prefers-color-scheme: dark) {
  .modal-content {
    background-color: #2e2e2e;
    color: #f1f1f1;
  }
  .modal-button {
    color: #fff;
    background-color: #3a3a3a;
  }
  .modal-button:hover {
    background-color: #575757;
  }
  .close-button {
    background-color: #3a3a3a;
  }
  .close-button:hover {
    background-color: #575757;
  }
}

/* Keyframes for animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(40px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.train-item.highlight {
  animation: highlightAnimation 2s ease-out;
}

@keyframes highlightAnimation {
  0% {
    background-color: #fffae5;
  }
  100% {
    background-color: transparent;
  }
}

.jump-button {
  display: inline-block;
  margin: 0px 0px 20px 0px;
  padding: 5px 24px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #000; /* White text */
  background: linear-gradient(
    to right,
    #cfd8dc,
    /* Light gray-blue from the theme */ #b0bec5 50%,
    /* Subtle transition to medium gray-blue */ #90a4ae 100%
      /* Deeper blue-gray for the edges */
  );
  border: none;
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.jump-button:hover {
  background: linear-gradient(
    to right,
    #b0bec5,
    /* Slightly darker gradient on hover */ #90a4ae 50%,
    #78909c 100%
  );
  transform: scale(1.05); /* Slightly enlarge on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Stronger shadow on hover */
}

.jump-button:active {
  transform: scale(0.95); /* Slightly smaller on click */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reduced shadow on click */
}

.jump-button:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Focus ring */
}

/* Center the button on the page */
.train-results + .jump-button {
  display: block;
  text-align: center;
}

/* Dark mode adjustments */
@media (prefers-color-scheme: dark) {
  .jump-button {
    color: #ddd; /* Light text color for dark mode */
    background: linear-gradient(
      to right,
      #455a64,
      /* Dark gray-blue from the theme */ #37474f 50%,
      /* Slightly lighter for contrast */ #263238 100% /* Deep blue-gray edge */
    );
    border: 2px solid #37474f; /* Border matching the gradient */
  }

  .jump-button:hover {
    background: linear-gradient(
      to right,
      #37474f,
      /* Darker hover effect */ #263238 50%,
      #1c252b 100%
    );
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5); /* Stronger shadow for hover */
  }

  .jump-button:focus {
    box-shadow: 0 0 0 3px rgba(55, 71, 79, 0.6); /* Dark mode focus ring */
  }
}

.button-row {
  display: flex;
  gap: 10px; /* Space between buttons */
  margin-bottom: 20px;
}

.jump-button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.jump-button:hover {
  background-color: #0056b3;
}

.prices-section {
  margin-top: 10px;
}

.prices-table {
  width: 100%;
  border-collapse: collapse;
}

.prices-table th,
.prices-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.prices-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}