/* Navbar container */
.navbar {
    background-color: var(--bg-color);
    color: var(--text-color);
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    /* Sticks to the top on scroll */
    top: 0;
    z-index: 1000;
}

/* Navbar inner container */
.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

/* Navbar logo */
.navbar-logo {
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
    color: var(--text-color);
}

/* Navbar links list */
.navbar-links {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
}

/* Navbar link items */
.navbar-link {
    text-decoration: none;
    color: var(--text-color);
    font-size: 16px;
    transition: color 0.2s ease;
}

.navbar-link:hover {
    color: var(--active-text);
    /* Highlight on hover */
}


.navbar-link.active {
  color: black;
  padding: 5px;
  border-bottom: 2px solid var(--active-border);
  background-color: #e3e6e8;
  border-radius: 8px 8px 0 0; /* Rounded top corners for tab effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Optional shadow for depth */
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
    .navbar {
        background-color: var(--card-bg);
    }

    .navbar-link.active {
        background-color: #263238;
        color: #fff;
    }
}