:root {
  --green: #28a745; /* Good for light and dark modes */
  --yellow: #ffc107;
  --red: #dc3545;
}

[data-theme="dark"] {
  --green: #50fa7b;
  --yellow: #f1fa8c;
  --red: #ff5555;
}

.ai-alerts {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 15px;
  margin: 0px 0;
  background-color: var(--bg-color);
  color: var(--text-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.ai-alerts-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.ai-alerts-header h3 {
  margin: 0;
  font-size: 18px;
}

.ai-alerts .collapse-icon {
  font-size: 20px;
  font-weight: bold;
  color: var(--text-color);
  transition: transform 0.3s ease;
}

.ai-alerts .collapse-icon:hover {
  transform: scale(1.2);
}

.ai-alerts-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ai-alert {
  display: flex;
  align-items: center;
  margin-top: 10px; /* Increased spacing between rows */
  font-size: 16px;
}

.ai-alert:last-child {
  margin-bottom: 0; /* Remove margin for the last alert */
}

.material-icons {
  font-size: 20px;
  vertical-align: middle;
  margin-right: 8px; /* Space between the icon and text */
}

.ai-alerts a {
  color: var(--link-color);
  text-decoration: underline;
}

.ai-alerts a:hover {
  text-decoration: none;
}
