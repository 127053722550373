/* Parent container for vertical lines */
.line-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Full width of the track */
    height: 100%; /* Full height of the track */
    pointer-events: none; /* Allow clicks to pass through */
}

/* Vertical lines */
.line {
    position: absolute; /* Position lines explicitly */
    top: 0;
    width: 2px; /* Thickness of the line */
    height: 100%; /* Full height of the track */
    background-color: lightgray;
}

/* Specific colors for each line */
.line.local {
    left: 5px; /* Position for Local line */
    background-color: #a2a2a2; /* Green */
}

.line.limited {
    left: 30px; /* Position for Limited line */
    background-color: rgb(153, 215, 220);
}

.line.express {
    left: 55px; /* Position for Express line */
    background-color: rgb(206, 32, 47);
}

.line.southcounty {
    left: 80px; /* Position for South County line */
    background-color: rgb(250, 228, 167);
    clip-path: polygon(
        0% var(--start-position, 0%),
        100% var(--start-position, 0%),
        100% 100%,
        0% 100%
    );
}

/* Track container */
.track-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px; /* Width to fit dots and names */
    height: 120vh; /* Dynamic height based on viewport */
}

/* Track styling */
.track {
    position: relative;
    width: 100%;
    height: 100%; /* Inherit from track-container */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly; /* Space stops evenly */
}

/* Container for each stop */
.stop-item {
    display: flex;
    align-items: center; /* Align dots and text horizontally */
    position: relative; /* For proper dot and name positioning */
    z-index: 1; /* Ensure dots are above lines */
    gap: 13px; /* Space between dots and stop name */
}

/* Dots (Stop Types) */
.stop {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    flex-shrink: 0; /* Prevent shrinking */
    background-color: lightgray;
}

/* Specific Stop Types */
.stop.local {
    background-color: #a2a2a2; /* Green for Local */
}

.stop.limited {
    background-color: rgb(153, 215, 220);
}

.stop.express {
    background-color: rgb(206, 32, 47);
}

.stop.southcounty {
    background-color: rgb(250, 228, 167);
}

.stop.blank {
    background-color: transparent; /* Invisible for blank */
}

/* Stop Name */
.stop-name {
    font-size: 14px; /* Adjust text size */
    color: #333;
    margin-left: 6px; /* Space between dots and name */
}

@media (prefers-color-scheme: dark) {
    .stop-name {
        color: #ddd; /* Light text for dark mode */
    }
}

/* Base styling for the train icon */
.train-icon {
    position: absolute;
    width: 30px;
    height: 30px;
    text-align: center;
    z-index: 2; /* Ensure above lines */
}

@media (prefers-color-scheme: dark) {
    .train-icon {
        color: #fcfff2; /* Light text for dark mode */
    }
}

/* Train number styling */
.train-number {
    position: absolute;
    font-size: 10px;
    font-weight: bold;
    color: white;
    text-align: center;
    width: 100%;
}

@media (prefers-color-scheme: dark) {
    .train-number {
        color: #000000; /* Light text for dark mode */
    }
}

/* Northbound triangle (pointing up) */
.train-icon.northbound {
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 30px solid;
}

.train-icon.northbound .train-number {
    bottom: -30px; /* Position below the triangle */
    left: -8px;
}

/* Southbound triangle (pointing down) */
.train-icon.southbound {
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 30px solid;
}

.train-icon.southbound .train-number {
    top: -30px; /* Position above the triangle */
    left: -8px;
}

.line.southcounty.hide-north-of-tamien {
    background: transparent; /* Or any other way to "hide" the line */
    height: calc(
        var(--tamien-position, 100%) - 5px
    ); /* Dynamically adjust to start at Tamien */
}
