.service-alerts {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 15px;
  margin: 0px 0;
  background-color: var(--bg-color);
  color: var(--text-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.service-alerts-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.service-alerts-header h3 {
  margin: 0;
  font-size: 18px;
}

.collapse-icon {
  font-size: 20px;
  font-weight: bold;
  color: var(--text-color);
  transition: transform 0.3s ease;
}

.collapse-icon:hover {
  transform: scale(1.2);
}

.service-alerts ul {
  list-style: none; /* Remove default list styling */
  padding: 0;
  margin: 10px 0 0;
}

.service-alerts li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px; /* Add space for the dot */
}

.service-alerts li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 8px; /* Adjust size for the dot */
  height: 8px; /* Adjust size for the dot */
  background-color: var(--text-color); /* Match text color */
  border-radius: 50%; /* Make it a circle */
}
.track-alert {
  background-color: var(--alert-bg);
  padding: 10px;
  border-radius: 5px;
}

/* Optional: Different color for track alerts */
.service-alerts .track-alert::before {
  background-color: var(--alert-bg); /* Distinct color for track alerts */
}

a {
  color: var(--link-color);
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}